import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import TwoWTech from "../../assets/Icon With Bot-light.svg";
import TwoWTechDark from "../../assets/Icon With Bot-dark.svg";
import { CommandButton, Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Layout = () => {
    const appStateContext = useContext(AppStateContext)
    const ui = appStateContext?.state.frontendSettings?.ui;
    const [announcementIndex, setAnnouncementIndex] = useState(0);
    const [announcements, setAnnouncements] = useState<string[]>([]);
    const [cycleCount, setCycleCount] = useState(0);

    const handleMenuClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_MENU' })
    };

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    useEffect(() => {
        const newAnnouncements = appStateContext?.state.frontendSettings?.ui?.announcements;
        if (newAnnouncements && newAnnouncements.length > 0) {
          setAnnouncements(newAnnouncements);
          setAnnouncementIndex(0);
        }
      }, [appStateContext?.state.frontendSettings?.ui?.announcements]);

    useEffect(() => {
    // Extract announcements outside of useEffect to use as a dependency
    const announcements = appStateContext?.state.frontendSettings?.ui?.announcements;

    const interval = setInterval(() => {
        let announcementList = announcements || [];
        if (announcementList.length > 1) {
            setAnnouncementIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % announcementList.length;
                // If newIndex is 0, it means we've completed a cycle through the list
                console.log("New Index: ", newIndex)
                if (newIndex === 0) {
                    console.log("CycleCount: ", cycleCount)
                    setCycleCount((prevCount) => {
                        console.log("Prevoius count: ", prevCount)
                        const newCount = prevCount + 1;

                        // Step 3: Check if the cycle count has reached 3
                        if (newCount > 1) {
                            clearInterval(interval); // Stop the interval
                            // clear the announcement
                            setAnnouncementIndex(announcementList.length + 1);
                        }
                        return newCount;
                    });
                }
                return newIndex;
            });
        }else{
            setAnnouncementIndex(0);
            console.log("CycleCount: ", cycleCount)
            setCycleCount((prevCount) => {
                console.log("Prevoius count: ", prevCount)
                const newCount = prevCount + 1;

                // Step 3: Check if the cycle count has reached 3
                if (newCount === 3) {
                    clearInterval(interval); // Stop the interval
                    // clear the announcement
                    setAnnouncementIndex(1);
                }
                return newCount;
            });
        }
    }, 30000);
    
    return () => clearInterval(interval);
    // Add announcements as a dependency
    }, [appStateContext?.state.frontendSettings?.ui?.announcements]);

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth >= 580) {
            appStateContext?.dispatch({ type: 'SET_DEVICE_TYPE', payload: { isMobile: false, isTablet: false, isDesktop: true } });
          } else {
            if (window.innerWidth > 320){
                appStateContext?.dispatch({ type: 'SET_DEVICE_TYPE', payload: { isMobile: false, isTablet: true, isDesktop: false } });
            }else{
                appStateContext?.dispatch({ type: 'SET_DEVICE_TYPE', payload: { isMobile: true, isTablet: false, isDesktop: false } });
            }
          }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);


    // Temporary fix for the homepage link
    function navigateToHomepage(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        window.open("/", '_self');
    }

    return (
        <div className={styles.layout}>
            <header className={appStateContext?.state.isDarkMode ? styles.darkmodeHeader : styles.header} role={"banner"}>
                <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal verticalAlign="center">
                        <img
                            src={appStateContext?.state.isDarkMode ? ui?.dark_logo ? ui.dark_logo : ui?.logo ? ui.logo : TwoWTechDark : ui?.logo ? ui.logo : TwoWTech}
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        <Link to="/" onClick={navigateToHomepage} className={styles.headerTitleContainer}>
                            <h1 className={appStateContext?.state.isDarkMode ? styles.darkmodeHeaderTitle : styles.headerTitle}>{ui?.title ? ui.title + "™" : ""}</h1>
                        </Link>
                    </Stack>
                    {appStateContext?.state.frontendSettings?.ui?.announcements && appStateContext?.state.frontendSettings.ui?.announcements?.length > 0 && appStateContext?.state.deviceType?.isDesktop == true && 
                    <Stack horizontalAlign="center" className={styles.announcementBar}>
                        <p className={styles.announcementScroll}>{announcements[announcementIndex]}</p>
                    </Stack>}
                    <Stack horizontal tokens={{ childrenGap: 4 }} className={styles.topButtonContainer}>
                        <button onClick={handleMenuClick} style={{ width: '45px', height: '40px', borderRadius: '8px' }}>
                            <FontAwesomeIcon icon={faBars} style={{ fontSize: '32px' }} />
                        </button>
                    </Stack>
                </Stack>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
