import React, { createContext, useReducer, ReactNode, useEffect } from 'react';
import { appStateReducer } from './AppReducer';
import { Modules, Conversation, ChatHistoryLoadingState, ModuleLoadingState, CosmosDBHealth, historyList, historyEnsure, CosmosDBStatus, frontendSettings, FrontendSettings, Feedback, moduleList, moduleEnsure, DeviceType, sharedConv, CosmosUser, EntraUser, ClientState } from '../api';
  
export interface AppState {
    isChatHistoryOpen: boolean;
    ismoduleListOpen: boolean;
    isFileBeingAnalyzed: boolean;
    isDarkMode: boolean;
    moduleLoadingState: ModuleLoadingState;
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    moduleList: Modules[] | null;
    currentChat: Conversation | null;
    currentModule: Modules | null;
    frontendSettings: FrontendSettings | null;
    feedbackState: { [answerId: string]: Feedback.Neutral | Feedback.Positive | Feedback.Negative; };
    deviceType: DeviceType | null;
    isSharedConv: boolean;
    isModuleDefPanelOpen: boolean;
    isMenuOpen: boolean;
    fileLoaded: number;
    focusIntent: Modules[] | null;
    loadedQuestion: string | null;
    isGenerating: boolean;
    isAdmin: boolean;
    cosmosUsers: CosmosUser[] | null;
    entraUsers: EntraUser[] | null;
    clientState: ClientState | null;
}

export type Action =
    | { type: 'TOGGLE_CHAT_HISTORY' }
    | { type: 'TOGGLE_MENU' }
    | { type: 'TOGGLE_DARK_MODE' }
    | { type: 'SET_GENERATING_STATE', payload: boolean}
    | { type: 'SET_IS_ADMIN', payload: boolean }
    | { type: 'TOGGLE_MODULE_DEF', payload: boolean }
    | { type: 'SET_LOADED_QUESTION', payload: string | null}
    | { type: 'TOGGLE_FILE_ANALYSIS', payload: boolean}
    | { type: 'FILE_LOADED', payload: number }
    | { type: 'SET_CLIENT_STATE', payload: ClientState }
    | { type: 'TOGGLE_SHARED_CONV', payload: boolean }
    | { type: 'SET_DEVICE_TYPE', payload: DeviceType}
    | { type: 'SET_COSMOSDB_STATUS', payload: CosmosDBHealth }
    | { type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState }
    | { type: 'UPDATE_CURRENT_CHAT', payload: Conversation | null }
    | { type: 'UPDATE_FILTERED_CHAT_HISTORY', payload: Conversation[] | null }
    | { type: 'UPDATE_CHAT_HISTORY', payload: Conversation } // API Call
    | { type: 'UPDATE_CHAT_TITLE', payload: Conversation } // API Call
    | { type: 'DELETE_CHAT_ENTRY', payload: string } // API Call
    | { type: 'DELETE_CHAT_HISTORY'}  // API Call
    | { type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: string }  // API Call
    | { type: 'FETCH_CHAT_HISTORY', payload: Conversation[] | null }  // API Call
    | { type: 'FETCH_FRONTEND_SETTINGS', payload: FrontendSettings | null }  // API Call
    | { type: 'UPDATE_COSMOS_USERS', payload: CosmosUser[] | null }
    | { type: 'UPDATE_ENTRA_USERS', payload: EntraUser[] | null }
    | { type: 'SET_FEEDBACK_STATE'; payload: { answerId: string; feedback: Feedback.Positive | Feedback.Negative | Feedback.Neutral } }
    | { type: 'GET_FEEDBACK_STATE'; payload: string }
    | { type: 'TOGGLE_MODULE_LIST' }
    | { type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState }
    | { type: 'UPDATE_CURRENT_MODULE', payload: Modules | null }
    | { type: 'FETCH_MODULE_LIST', payload: Modules[] | null }
    | { type: 'FOCUS_MODULE_INTENT', payload: Modules[] | null };

const initialState: AppState = {
    isChatHistoryOpen: false,
    isMenuOpen: false,
    isDarkMode: true,
    isSharedConv: false,
    isFileBeingAnalyzed: false,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    moduleLoadingState: ModuleLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    currentModule: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured,
    },
    frontendSettings: null,
    feedbackState: {},
    ismoduleListOpen: false,
    moduleList: null,
    deviceType: {
        isMobile: false,
        isTablet: false,
        isDesktop: true
    },
    isModuleDefPanelOpen: false,
    fileLoaded: 0,
    focusIntent: null,
    loadedQuestion: null,
    isGenerating: false,
    isAdmin: false,
    cosmosUsers: null,
    entraUsers: null,
    clientState: null,
};

export const AppStateContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<Action>;
  } | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
  };
  
  export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);

    const getFrontendSettings = async () => {
        frontendSettings().then((response) => {
            let frontendSettings = response as FrontendSettings;
            if(frontendSettings !== null){
                if (frontendSettings?.redirect_url){
                    // There is something in the redirect_url which means we need to redirect the user's browser
                    window.location.href = frontendSettings.redirect_url;
                }
                dispatch({ type: 'FETCH_FRONTEND_SETTINGS', payload: frontendSettings });
                dispatch({ type: 'SET_IS_ADMIN', payload: frontendSettings?.isAdmin || false });
                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let browser = navigator.userAgent;
                let url = window.location.href;
                dispatch({ type: 'SET_CLIENT_STATE', payload: { timeZone: timeZone, browser: browser, url: url } });
            }
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
        })
        // Since the user has come back authorized, we can now get the user data
        getAuthorizedUserData();
    }

    const getAuthorizedUserData = async () => {
        // Get the chat history and module list
        getHistoryEnsure();
        getModuleEnsure();
    
        // Check if the user is trying to access a shared conversation
        const urlParams = new URLSearchParams(window.location.search);
        const conversationId = urlParams.get('conversationId');
        if(conversationId){
            historyEnsure().then((response) => {
                if(response?.cosmosDB){
                    sharedConv(conversationId).then((response) => {
                        if(response){
                            dispatch({ type: 'TOGGLE_SHARED_CONV', payload: true });
                            dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: response });
                            dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: response });
                        }
                    })
                }
            })
            // Change the URL to remove all the query parameters
            window.history.pushState({}, document.title, window.location.pathname);
        }
    }

    const fetchChatHistory = async (offset=0): Promise<Conversation[] | null> => {
        const result = await historyList(offset).then((response) => {
            if(response){
                dispatch({ type: 'FETCH_CHAT_HISTORY', payload: response});
            }else{
                dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
            }
            return response
        })
        .catch((err) => {
            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
            dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
            console.error("There was an issue fetching your data.");
            return null
        })
        return result
    };

    const fetchmoduleList = async (offset=0): Promise<Modules[] | null> => {
        const result = await moduleList(offset).then((response) => {
            if(response){
                dispatch({ type: 'FETCH_MODULE_LIST', payload: response});
            }else{
                dispatch({ type: 'FETCH_MODULE_LIST', payload: null });
            }
            return response
        })
        .catch((err) => {
            dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Fail });
            dispatch({ type: 'FETCH_MODULE_LIST', payload: null });
            console.error("There was an issue fetching your data.");
            return null
        })
        return result
    }

    const getHistoryEnsure = async () => {
        dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Loading });
        historyEnsure().then((response) => {
            if(response?.cosmosDB){
                fetchChatHistory()
                .then((res) => {
                    if(res){
                        dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Success });
                        dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                    }else{
                        dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                        dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotWorking} });
                    }
                })
                .catch((err) => {
                    dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotWorking} });
                })
            }else{
                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
            }
        })
        .catch((err) => {
            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotConfigured} });
        })
    }

    const getModuleEnsure = async () => {
        dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Loading });
        moduleEnsure().then((response) => {
            if(response?.cosmosDB){
                if(state.moduleList === null){
                    fetchmoduleList()
                    .then((res) => {
                        if(res){
                            dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Success });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                        }else{
                            dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Fail });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotWorking} });
                        }
                    })
                    .catch((err) => {
                        dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Fail });
                        dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotWorking} });
                    })
                }
            }else{
                dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Fail });
                dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
            }
        })
        .catch((err) => {
            dispatch({ type: 'UPDATE_MODULE_LOADING_STATE', payload: ModuleLoadingState.Fail });
            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotConfigured} });
        })
    }

    useEffect(() => {
        // Get the frontend settings and verify if the user is authorized to use the app
        getFrontendSettings();
    }, []);
  
    return (
      <AppStateContext.Provider value={{ state, dispatch }}>
        {children}
      </AppStateContext.Provider>
    );
  };


