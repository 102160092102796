import {  Stack, StackItem, Text } from "@fluentui/react";

import styles from "./MenuPanel.module.css"
import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { CosmosDBStatus } from "../../api";
import { ChatHistoryPanel } from "../ChatHistory/ChatHistoryPanel";
import { ModulePanel } from "../Module/ModulePanel";
import { HistoryButton, IsAdminButton, ModuleButton } from "../common/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';


interface MenuPanelProps {

}

export function MenuPanel(props: MenuPanelProps) {
    const appStateContext = useContext(AppStateContext)
    
    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    const handleDarkModeToggle = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_DARK_MODE' })

    };

    const handleModuleClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_MODULE_LIST' })
    };

    const openAdminPortal = () => {
        const url = location.pathname === "/" ? '/adminportal' : '/';
        window.open(url, '_self');
    }

    return (
        <section className={(appStateContext?.state.isDarkMode) ? styles.containerDark : styles.container} data-is-scrollable aria-label={"module panel"}>
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center' wrap aria-label="menu header" className={styles.menuOptionContainer}>
                <Stack verticalAlign="start" aria-label="menu header" styles={{ root: { width: '100%', maxHeight: 'calc(100vh - 125px)' }}} >
                    <StackItem>
                        <Text role="heading" aria-level={2} style={{ alignSelf: "center", fontWeight: "600", fontSize: "18px", marginRight: "auto", paddingLeft: "20px", color:(appStateContext?.state.isDarkMode) ? "White" : "Black" }}>Menu</Text>
                    </StackItem>
                    {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                        <ModuleButton onClick={handleModuleClick} text={appStateContext?.state?.ismoduleListOpen ? "Hide ResolveIQ Modules" : "Show ResolveIQ Modules"} />
                    }
                    {(appStateContext?.state.ismoduleListOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ModulePanel />}
                    {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                        <HistoryButton onClick={handleHistoryClick} text={appStateContext?.state?.isChatHistoryOpen ? "Hide chat history" : "Show chat history"} />
                    }
                    {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel />}            
                    </Stack>
            </Stack>
            {appStateContext?.state.isAdmin || location.pathname !== "/" ?
            (
                <div aria-label={location.pathname === "/" ? "launch admin portal button" : "launch chat"} className={styles.adminButtonContainer}>
                    <IsAdminButton onClick={openAdminPortal} text={location.pathname === "/" ? 'Admin Portal' : 'Chat'} />
                </div>
            ) : null}
            <div aria-label={"darkmode button"} className={styles.darkModeButtonContainer}>
                <button type="button" onClick={handleDarkModeToggle}>
                    {(appStateContext?.state.isDarkMode) ? <FontAwesomeIcon icon={faSun} style={{ fontSize: '32px' }} /> : <FontAwesomeIcon icon={faMoon} style={{ fontSize: '32px' }} />}
                </button>
            </div>
        </section>
    );
}