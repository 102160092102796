export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    message_id?: string;
    feedback?: Feedback;
    module_name?: string;
};

export type FeedbackMessage = {
    id: string;
    conversationId: string;
    feedback: Feedback;
    userId: string;
    title: string;
    createdAt: string;
    module_name: string;
}

export type ClientState = {
    timeZone: string;
    browser: string;
    url: string;
};

export type Citation = {
    part_index?: number;
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
}

export type CitationsMessageContent = {
    citations: Citation[];
    intent: string;
}

export type MessageContent = {
    type: string;
    text?: string;
    file?: string;
    image_url?: string;
}

export type ChatURL = {
    "url": string;
}

export type ChatMessage = {
    id: string;
    role: string;
    content: string;
    end_turn?: boolean;
    date: string;
    feedback?: Feedback;
    context?: string;
    azureExtensionsContext?: CitationsMessageContent;
    files?: FileUploadResults[];
    encrypted?: boolean;
    module_name?: string;
    tenant_id?: string;
};

export type FileUploadResults = {
    fileName: string;
    fileType: string;
    fileData: string;
    dataType: string;
    fileEncrypted?: boolean;
}

export type AvailableModules = {
    moduleName: string;
}

export type Conversation = {
    id: string;
    title: string;
    messages: ChatMessage[];
    date: string;
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    error?: any;
    chosen_intent?: Modules;
    "apim-request-id"?: string;
}

export type ConversationRequest = {
    messages: ChatMessage[];
    focus?: Modules[];
    client_state?: ClientState | null;
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    InvalidCredentials = "CosmosDB has invalid credentials",
    InvalidDatabase = "Invalid CosmosDB database name",
    InvalidContainer = "Invalid CosmosDB container name",
    Working = "CosmosDB is configured and working",
}

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string
}

export type DeviceType ={
    isMobile: boolean,
    isTablet: boolean,
    isDesktop: boolean
}

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export enum ModuleLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export type ErrorMessage = {
    title: string,
    subtitle: string
}

export type UI = {
    title: string;
    chat_title: string;
    chat_description: string;
    logo?: string;
    dark_logo?: string;
    chat_logo?: string;
    dark_chat_logo?: string;
    show_share_button?: boolean
    announcements?: string[];
}

export type FrontendSettings = {
    auth_enabled?: string | null;
    feedback_enabled?: string | null;
    ui?: UI;
    sanitize_answer?: boolean;
    isAdmin?: boolean;
    dev_mode?: boolean;
    user_name?: string;
    redirect_url?: string;
}

export enum Feedback {
    Neutral = "neutral",
    Positive = "positive",
    Negative = "negative",
    MissingCitation = "missing_citation",
    WrongCitation = "wrong_citation",
    OutOfScope = "out_of_scope",
    InaccurateOrIrrelevant = "inaccurate_or_irrelevant",
    OtherUnhelpful = "other_unhelpful",
    HateSpeech = "hate_speech",
    Violent = "violent",
    Sexual = "sexual",
    Manipulative = "manipulative",
    OtherHarmful = "other_harmlful",
    OtherContent = "other_content",
}


export type Modules = PortalModule & Module;

export type PortalModule = {
    enabled?: boolean;
    default?: boolean;
    moduleName?: string;
    name?: string;
    searchIndex?: string[];
}

export type Module = {
    id: string;
    licensed: string;
    video: string;
    definition: string;
    aiDefinition?: string;
    functionName?: string;
    quickQuery?: any[];
    requireFocus?: boolean;
    tool_call_limit?: number;
    in_scope?: boolean;
    temperature?: number;
}

export type CosmosUser = {
    user_id: string;
    user_name?: string;
    modules?: PortalModule[];
    display_name?: string;
    first_name?: string;
    last_name?: string;
    isAdmin?: boolean;
}

export type EntraUser = {
    id: string;
    userPrincipalName: string;
    givenName: string;
    surname: string;
}

export type UserConfigChange = {
    state_id: string;
    user_id?: string;
    module_name?: string;
    enabled?: boolean;
    search_index?: string[];
}

export type HandleAllChanged = {
    module_name: string;
    changed: boolean;
}

export type SortedObjects = {
    name: string;
    status: string;
}