import * as React from 'react';
import { Checkbox, Dialog, DialogFooter, DialogType, List, Separator, Stack, ITextField, Spinner, SpinnerSize } from '@fluentui/react';


import { AppStateContext } from '../../state/AppProvider';
import { GroupedModules } from './ModuleList';

import styles from "./ModulePanel.module.css"
import { useBoolean } from '@fluentui/react-hooks';
import { Modules } from '../../api/models';
import { moduleList } from '../../api';
import { useEffect, useRef, useState, useContext, FormEvent } from 'react';
import { getCheckboxStyles } from '../common/Button';

interface ModuleListItemCellProps {
  item?: Modules;
  onSelect: (item: Modules | null) => void;
}

interface ModuleListItemGroupsProps {
    groupedModules: GroupedModules[];
}

export const ModuleListItemCell: React.FC<ModuleListItemCellProps> = ({
  item,
  onSelect,
}) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [edit, setEdit] = useState(false);
    const [hideDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(true);
    const [textFieldFocused, setTextFieldFocused] = useState(false);
    const textFieldRef = useRef<ITextField | null>(null);
    
    const appStateContext = React.useContext(AppStateContext)
    const isSelected = item?.name === appStateContext?.state.currentModule?.name;
    const dialogContentProps = {
        type: DialogType.close,
        title: 'Are you sure you want to delete this item?',
        closeButtonAriaLabel: 'Close',
        subText: 'The history of this chat session will permanently removed.',
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    if (!item) {
        return null;
    }

    useEffect(() => {
        if (textFieldFocused && textFieldRef.current) {
            textFieldRef.current.focus();
            setTextFieldFocused(false);
        }
    }, [textFieldFocused]);

    const moduleChecked = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean)  => {
        const isChecked = checked;
        const focusedModules = appStateContext?.state.focusIntent ? appStateContext?.state.focusIntent : [];

        if (isChecked) {    
            // Checkbox was checked        
            // Check to see if the module is already in the list and if the module is not in the list, add it
            if (!focusedModules?.some((module) => module.name === item.name)) {
                focusedModules?.push(item);
            }

            appStateContext?.dispatch({ type: 'FOCUS_MODULE_INTENT', payload: focusedModules });
        } else {
            // Checkbox was unchecked
            // Remove the module from the list
            const index = focusedModules?.findIndex((module) => module.name === item.name);
            if (index !== -1) {
                focusedModules?.splice(index, 1);
            }

            // If the list is now empty, remove the focus intent
            if (focusedModules?.length === 0) {
                appStateContext?.dispatch({ type: 'FOCUS_MODULE_INTENT', payload: null });
            }else{
                appStateContext?.dispatch({ type: 'FOCUS_MODULE_INTENT', payload: focusedModules });
            }
        }
        
        appStateContext?.dispatch({ type: 'TOGGLE_MODULE_DEF', payload: false })
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_MODULE', payload: null });
    };
    

    const handleSelectItem = () => {
        onSelect(item)
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_MODULE', payload: item } )
        appStateContext?.dispatch({ type: 'TOGGLE_MODULE_DEF', payload: true })
    }

    const truncatedModuleName = (item?.name && item?.name?.length > 28) ? `${item.name.substring(0, 28)} ...` : item.name;

    return (
        <Stack
            key={item.name}
            tabIndex={0}
            aria-label='available modules item'
            className={appStateContext?.state.isDarkMode ? styles.itemCellDark : styles.itemCell}
            onClick={() => handleSelectItem()}
            onKeyDown={e => e.key === "Enter" || e.key === " " ? handleSelectItem() : null}
            verticalAlign='center'
            // horizontal
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            styles={{
                root: {
                    backgroundColor: appStateContext?.state.isDarkMode ? isSelected ? '#4b62d8' : 'transparent' : isSelected ? '#e6e6e6' : 'transparent',
                }
            }}
        >
            {edit ? <>
                <Stack.Item 
                    style={{ width: '100%' }}
                >
                </Stack.Item>
            </> : <>
                <Stack horizontal verticalAlign={'center'} style={{ width: '100%' }}>
                    <div className={styles.moduleName}>{truncatedModuleName}</div>
                    {(isSelected || isHovered) && <Stack horizontal horizontalAlign='end'>
                       
                    </Stack>}
                    {item.licensed === "Available" && 
                    <Checkbox
                    id={item.name + 'Check'}
                    defaultChecked={appStateContext?.state.focusIntent?.some((module) => module.name === item.name)}
                    onChange={moduleChecked}
                    // Apply the custom styles function to the styles prop
                    styles={(props) => getCheckboxStyles(props?.checked ? true : false, "module")}
                  />
                    }
                </Stack>
            </>
            }
            
            <Dialog
                hidden={hideDeleteDialog}
                onDismiss={toggleDeleteDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};

export const ModuleListItemGroups: React.FC<ModuleListItemGroupsProps> = ({ groupedModules }) => {
    const appStateContext = useContext(AppStateContext);
    const observerTarget = useRef(null);
    const [ , setSelectedItem] = React.useState<Modules | null>(null);
    const [offset, setOffset] = useState<number>(25);
    const [observerCounter, setObserverCounter] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const firstRender = useRef(true);

  const handleSelectModule = (item?: Modules) => {
    if(item){
        setSelectedItem(item)
    }
  }

  const onRenderCell = (item?: Modules) => {
    return (
      <ModuleListItemCell item={item} onSelect={() => handleSelectModule(item)} />
    );
  };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        handleFetchModules();
        setOffset((offset) => offset += 25);
    }, [observerCounter]);

    const handleFetchModules = async () => {
        setShowSpinner(true);

        if (appStateContext?.state.moduleList === null) {
            await moduleList(offset).then((response) => {
                if (response) {
                    appStateContext?.dispatch({ type: 'FETCH_MODULE_LIST', payload: response });
                } else {
                    appStateContext?.dispatch({ type: 'FETCH_MODULE_LIST', payload: null });
                }
                setShowSpinner(false);
                return response
            })
        }else{
            appStateContext?.dispatch({ type: 'FETCH_MODULE_LIST', payload: appStateContext?.state.moduleList });
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) 
                    setObserverCounter((observerCounter) => observerCounter += 1);
            },
            { threshold: 1 }
        );

        if (observerTarget.current) observer.observe(observerTarget.current);

        return () => {
            if (observerTarget.current) observer.unobserve(observerTarget.current);
        };
    }, [observerTarget]);

  return (
    <div className={styles.listContainer} data-is-scrollable>
      {groupedModules.map((group) => (
        group.entries.length > 0 && <Stack horizontalAlign="start" verticalAlign="center" key={group.availability} className={styles.moduleGroup} aria-label={`ResolveIQ Modules: ${group.availability}`}>
          <Stack aria-label={group.availability} className={styles.moduleAvailable}>{group.availability}</Stack>
          <List aria-label={`module list`} items={group.entries} onRenderCell={onRenderCell} className={styles.moduleList}/>
          <div ref={observerTarget} />
          <Separator styles={{
            root: {
                width: '100%',
                position: 'relative',
                '::before': {
                  backgroundColor: '#d6d6d6',
                },
              },
          }}/>
        </Stack>
      ))}
      {showSpinner && <div className={styles.spinnerContainer}><Spinner size={SpinnerSize.small} aria-label="loading more modules" className={styles.spinner}/></div>}
    </div>
  );
};
