import { GroupedList, IGroup, IGroupHeaderProps, IRenderFunction, List, Stack, StackItem, Text } from '@fluentui/react';
import React, { useContext } from 'react';
import { AppStateContext } from '../../state/AppProvider';
import { ModuleListItemGroups } from './ModuleListItem';
import { Modules } from '../../api/models';

interface moduleListProps {}

export interface GroupedModules {
    availability: string;
    entries: Modules[]
}

const groupByLicense = (entries: Modules[]) => {
    const groups: GroupedModules[] = [{ availability: "Available", entries: [] }];
  
    entries.forEach((entry) => {
        const licensed = entry.licensed;
        const existingGroup = groups.find((group) => group.availability === licensed);
        const moduleAdded = existingGroup?.entries.find((module) => module.id === entry.id);
        
        if(moduleAdded){
            return;
        }
        if (existingGroup) {
            existingGroup.entries.push(entry);
        } else {
            groups.push({ availability: licensed, entries: [entry] });
        }
    });
  
    return groups;
};

const moduleList: React.FC<moduleListProps> = () => {
    const appStateContext = useContext(AppStateContext);
    const moduleList = appStateContext?.state.moduleList;

    React.useEffect(() => {}, [appStateContext?.state.moduleList]);
    
    let groupedModules;
    if(moduleList && moduleList.length > 0){
        groupedModules = groupByLicense(moduleList);
    }else{
        return <Stack horizontal horizontalAlign='center' verticalAlign='center' style={{ width: "100%", marginTop: 10 }}>
            <StackItem>
                <Text style={{ alignSelf: 'center', fontWeight: '400', fontSize: 14, color:(appStateContext?.state.isDarkMode) ? "White" : "Black" }}>
                    <span>No Modules Available.</span>
                </Text>
            </StackItem>
        </Stack>
    }
    
    return (
        <ModuleListItemGroups groupedModules={groupedModules}/>
    );
};

export default moduleList;
