import { CommandBarButton, DefaultButton, IButtonProps, ICheckboxStyles } from "@fluentui/react";
import { AppStateContext } from "../../state/AppProvider";
import { useContext } from "react";

import styles from './Button.module.css';
import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";

// Function to generate custom styles based on the checked state
export const getCheckboxStyles = (checked: boolean, page: string): Partial<ICheckboxStyles> => {
  // determin the desgin of the checkbox based on the page
  var when_checked = {};
  var when_not_checked = {};
  
  switch (page) {
    case 'module':
      when_checked = {
        backgroundColor: '#273a74',
        borderColor: 'blue',
      };
      when_not_checked = {
        backgroundColor: 'white',
        borderColor: 'gray',
      };
      break;
    case 'user_management':
        when_checked = {
          backgroundColor: 'green',
          borderColor: 'blue',
          margin: 'auto 15px'
        };
        when_not_checked = {
          backgroundColor: 'white',
          borderColor: 'gray',
          margin: 'auto 15px'
        };
        break;
      case 'user_module_management':
          when_checked = {
            backgroundColor: '#273a74',
            borderColor: 'blue',
            margin: 'auto 15px',
            zIndex: 1
          };
          when_not_checked = {
            backgroundColor: 'white',
            borderColor: 'gray',
            margin: 'auto 15px',
            zIndex: 1
          };
          break;
      case 'changed_user_module_management':
        when_checked = {
          backgroundColor: 'green',
          borderColor: 'red',
          margin: 'auto 15px',
          zIndex: 1
        };
        when_not_checked = {
          backgroundColor: 'white',
          borderColor: 'red',
          margin: 'auto 15px',
          zIndex: 1
        };
        break;
    default:
      break;
  }

  return {
    // Apply custom styles for the checkbox when it is not checked
    checkbox: checked ? when_checked : when_not_checked,
    // You can add more custom styles for other parts of the Checkbox here
  };
};


interface ButtonProps extends IButtonProps {
  onClick: () => void;
  text: string | undefined;
}

export const ShareButton: React.FC<ButtonProps> = ({ onClick, text }) => {

  return (
    <CommandBarButton
      className={styles.shareButtonRoot}
      iconProps={{ iconName: 'Share' }}
      onClick={onClick}
      text={text}
    />
  )
}

export const HistoryButton: React.FC<ButtonProps> = ({ onClick, text }) => {
  return (
    <DefaultButton
      className={styles.historyButtonRoot}
      text={text}
      iconProps={{ iconName: 'History' }}
      onClick={onClick}
    />
  )
}

export const ModuleButton: React.FC<ButtonProps> = ({ onClick, text }) => {
  return (
    <DefaultButton
      className={styles.moduleButtonRoot}
      text={text}
      iconProps={{ iconName: 'Module' }}
      onClick={onClick}
    />
  )
}

export const DarkModeButton: React.FC<ButtonProps> = ({ onClick }) => {
  const appStateContext = useContext(AppStateContext)
  return (
    <DefaultButton
      className={styles.darkModeButtonRoot}
      text={appStateContext?.state.isDarkMode ? 'Light' : 'Dark'}
      iconProps={{
        iconName: 'DarkMode'
      }}
      onClick={onClick}
    />
  )
};

export const MenuButton: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <DefaultButton
      className={styles.darkModeButtonRoot}
      text={'Menu'}
      iconProps={{
        iconName: 'Menu'
      }}
      onClick={onClick}
    />
  )
};

export const IsAdminButton: React.FC<ButtonProps> = ({ onClick, text }) => {
  return (
    <DefaultButton
      className={styles.adminButtonRoot}
      text={ text }
      onClick={onClick}
      type='button'
    />
  )
}