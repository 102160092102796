import { Action, AppState } from './AppProvider';

// Define the reducer function
export const appStateReducer = (state: AppState, action: Action): AppState => {
    switch (action.type) {
        case 'TOGGLE_CHAT_HISTORY':
            return { ...state, isChatHistoryOpen: !state.isChatHistoryOpen };
        case 'FOCUS_MODULE_INTENT':
            return { ...state, focusIntent: action.payload };
        case 'TOGGLE_MENU':
            return { ...state, isMenuOpen: !state.isMenuOpen };
        case 'SET_CLIENT_STATE':
            return { ...state, clientState: action.payload };
        case 'TOGGLE_DARK_MODE':
            return { ...state, isDarkMode: !state.isDarkMode };
        case 'TOGGLE_FILE_ANALYSIS':
            return { ...state, isFileBeingAnalyzed: action.payload };
        case 'SET_DEVICE_TYPE':
            return { ...state, deviceType: action.payload };
        case 'TOGGLE_SHARED_CONV':
            return { ...state, isSharedConv: action.payload };
        case 'TOGGLE_MODULE_DEF':
            return { ...state, isModuleDefPanelOpen: action.payload };
        case 'SET_LOADED_QUESTION':
            return { ...state, loadedQuestion: action.payload };
        case 'TOGGLE_MODULE_LIST':
            return { ...state, ismoduleListOpen: !state.ismoduleListOpen };
        case 'UPDATE_CURRENT_CHAT':
            return { ...state, currentChat: action.payload };
        case 'UPDATE_CHAT_HISTORY_LOADING_STATE':
            return { ...state, chatHistoryLoadingState: action.payload };
        case 'UPDATE_CURRENT_MODULE':
            return { ...state, currentModule: action.payload };
        case 'FILE_LOADED':
            return { ...state, fileLoaded: action.payload };
        case 'UPDATE_MODULE_LOADING_STATE':
            return { ...state, moduleLoadingState: action.payload };
        case 'UPDATE_CHAT_HISTORY':
            if(!state.chatHistory || !state.currentChat){
                return state;
            }
            let conversationIndex = state.chatHistory.findIndex(conv => conv.id === action.payload.id);
            if (conversationIndex !== -1) {
                let updatedChatHistory = [...state.chatHistory];
                updatedChatHistory[conversationIndex] = state.currentChat
                return {...state, chatHistory: updatedChatHistory}
            } else {
                return { ...state, chatHistory: [...state.chatHistory, action.payload] };
            }
        case 'UPDATE_CHAT_TITLE':
            if(!state.chatHistory){
                return { ...state, chatHistory: [] };
            }
            let updatedChats = state.chatHistory.map(chat => {
                if (chat.id === action.payload.id) {
                    if(state.currentChat?.id === action.payload.id){
                        state.currentChat.title = action.payload.title;
                    }
                    return { ...chat, title: action.payload.title };
                }
                return chat;
            });
            return { ...state, chatHistory: updatedChats };
        case 'DELETE_CHAT_ENTRY':
            if(!state.chatHistory){
                return { ...state, chatHistory: [] };
            }
            let filteredChat = state.chatHistory.filter(chat => chat.id !== action.payload);
            state.currentChat = null;
            return { ...state, chatHistory: filteredChat };
        case 'DELETE_CHAT_HISTORY':
            return { ...state, chatHistory: [], filteredChatHistory: [], currentChat: null };
        case 'DELETE_CURRENT_CHAT_MESSAGES':
            if(!state.currentChat || !state.chatHistory){
                return state;
            }
            const updatedCurrentChat = {
                ...state.currentChat,
                messages: []
            };
            return {
                ...state,
                currentChat: updatedCurrentChat
            };
        case 'FETCH_CHAT_HISTORY':
            return { ...state, chatHistory: action.payload };
        case 'FETCH_MODULE_LIST':
            return { ...state, moduleList: action.payload };
        case 'SET_COSMOSDB_STATUS':
            return { ...state, isCosmosDBAvailable: action.payload };
        case 'FETCH_FRONTEND_SETTINGS':
            return { ...state, frontendSettings: action.payload };
        case 'SET_IS_ADMIN':
            return { ...state, isAdmin: action.payload };
        case 'SET_FEEDBACK_STATE':
            return {
                ...state,
                feedbackState: {
                    ...state.feedbackState,
                    [action.payload.answerId]: action.payload.feedback,
                },
            }; 
        case 'SET_GENERATING_STATE':
            return { ...state, isGenerating: action.payload }; 
        case 'UPDATE_COSMOS_USERS':
            return { ...state, cosmosUsers: action.payload,};
        case 'UPDATE_ENTRA_USERS':
            return { ...state, entraUsers: action.payload };
        default:
            return state;
      }
};