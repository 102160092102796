import { CommandBarButton, ContextualMenu, Dialog, DialogFooter, ICommandBarStyles, IContextualMenuItem, IStackStyles, Spinner, SpinnerSize, Stack, StackItem, Text } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';

import styles from "./ModulePanel.module.css"
import { useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import React from "react";
import ModuleList from "./ModuleList";
import { ModuleLoadingState } from "../../api";

interface ModulePanelProps {

}

export enum ModulePanelTabs {
    Modules = "Modules"
}

const commandBarStyle: ICommandBarStyles = {
    root: {
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
};

const commandBarButtonStyle: Partial<IStackStyles> = { root: { height: '50px' } };

export function ModulePanel(props: ModulePanelProps) {
    const appStateContext = useContext(AppStateContext)
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const [hideClearAllDialog, { toggle: toggleClearAllDialog }] = useBoolean(true);
    const clearing = React.useState(false)
    const [clearingError, setClearingError] = React.useState(false)
    
    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450} },
    }

    const menuItems: IContextualMenuItem[] = [
        { key: 'moreInfo', text: 'Get More Information', iconProps: { iconName: 'More Information' }},
    ];

    const handleModuleClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_MODULE_LIST' })
    };
    
    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault(); // don't navigate
        setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

    const onHideClearAllDialog = () => {
        toggleClearAllDialog()
        setTimeout(() => {
            setClearingError(false)
        }, 2000);
    }

    React.useEffect(() => {}, [appStateContext?.state.moduleList, clearingError]);

    return (
        <section className={(appStateContext?.state.isDarkMode) ? styles.containerDark : styles.container} data-is-scrollable aria-label={"module panel"}>
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center' wrap aria-label="module list header">
                <StackItem>
                    <Text role="heading" aria-level={2} style={{ alignSelf: "center", fontWeight: "600", fontSize: "18px", marginRight: "auto", paddingLeft: "20px", color:(appStateContext?.state.isDarkMode) ? "White" : "Black" }}>Module List</Text>
                </StackItem>
                <Stack verticalAlign="start">
                    <Stack horizontal styles={commandBarButtonStyle}>
                        <CommandBarButton
                            iconProps={{ iconName: 'More' }}
                            title={"Get More Information"}
                            onClick={onShowContextualMenu}
                            aria-label={"Get More Information"}
                            styles={commandBarStyle}
                            role="button"
                            id="moreButton"
                        />
                        <ContextualMenu
                            items={menuItems}
                            hidden={!showContextualMenu}
                            target={"#moreButton"}
                            onItemClick={toggleClearAllDialog}
                            onDismiss={onHideContextualMenu}
                        />
                        <CommandBarButton
                            iconProps={{ iconName: 'Cancel' }}
                            title={"Hide"}
                            onClick={handleModuleClick}
                            aria-label={"hide button"}
                            styles={commandBarStyle}
                            role="button"
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack aria-label="module panel content"
                styles={{
                    root: {
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        paddingTop: '2.5px',
                        maxWidth: "100%"
                    },
                }}
                style={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    flexWrap: "wrap",
                    padding: "1px"
                }}>
                <Stack className={styles.moduleListContainer}>
                    {(appStateContext?.state.moduleLoadingState === ModuleLoadingState.Success && appStateContext?.state.isCosmosDBAvailable.cosmosDB) && <ModuleList/>}
                    {(appStateContext?.state.moduleLoadingState === ModuleLoadingState.Fail && appStateContext?.state.isCosmosDBAvailable) && <>
                        <Stack>
                            <Stack horizontalAlign='center' verticalAlign='center' style={{ width: "100%", marginTop: 10 }}>
                                <StackItem>
                                    <Text style={{ alignSelf: 'center', fontWeight: '400', fontSize: 16, color:(appStateContext?.state.isDarkMode) ? "White" : "Black"}}>
                                        {appStateContext?.state.isCosmosDBAvailable?.status && <span>{appStateContext?.state.isCosmosDBAvailable?.status}</span>}
                                        {!appStateContext?.state.isCosmosDBAvailable?.status && <span>Error loading Module List</span>}
                                        
                                    </Text>
                                </StackItem>
                                <StackItem>
                                    <Text style={{ alignSelf: 'center', fontWeight: '400', fontSize: 14, color:(appStateContext?.state.isDarkMode) ? "White" : "Black"}}>
                                        <span>Module List can't be loaded at this time.</span>
                                    </Text>
                                </StackItem>
                            </Stack>
                        </Stack>
                    </>}
                    {appStateContext?.state.moduleLoadingState === ModuleLoadingState.Loading && <>
                        <Stack>
                            <Stack horizontal horizontalAlign='center' verticalAlign='center' style={{ width: "100%", marginTop: 10 }}>
                                <StackItem style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <Spinner style={{ alignSelf: "flex-start", height: "100%", marginRight: "5px" }} size={SpinnerSize.medium} />
                                </StackItem>
                                <StackItem>
                                    <Text style={{ alignSelf: 'center', fontWeight: '400', fontSize: 14, color:(appStateContext?.state.isDarkMode) ? "White" : "Black" }}>
                                        <span style={{ whiteSpace: 'pre-wrap'}}>Loading Module List</span>
                                    </Text>
                                </StackItem>
                            </Stack>
                        </Stack>
                    </>}
                </Stack>
            </Stack>
            <Dialog
                hidden={hideClearAllDialog}
                onDismiss={clearing ? ()=>{} : onHideClearAllDialog}
                modalProps={modalProps}
            >
                <DialogFooter>
                </DialogFooter>
            </Dialog>
        </section>
    );
}