import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, Modules,FileUploadResults, FeedbackMessage, CosmosUser } from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";
import { moduleListSampleData } from "../constants/moduleList";
import { add } from "lodash";

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages,
            focus: options.focus,
            client_state: options.client_state
        }),
        signal: abortSignal
    });

    return response;
}

export async function fetchFeedbacks(feedback: FeedbackMessage | null = null, approved:boolean=false, reject:boolean=false): Promise<FeedbackMessage[]> {
    const response = await fetch("/admin/feedback", {
        method: "POST",
        body: feedback ? JSON.stringify({
            feedback: feedback,
            approved: approved,
            reject: reject
        }) : JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        const payload = await res.json();
        if (!payload) {
            console.error("There was an issue fetching your data.");
            return null;
        }else if (feedback){
            return null;
        }else{
            return payload;
        }       
        
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    });

    return response;
}

export async function removeMessage(messageId: string): Promise<Boolean> {
    const response = await fetch("/history/remove-message", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        const payload = await res.json();
        if (!payload) {
            console.error("There was an issue removing the message.");
            return false;
        }else{
            return true;
        }       
        
    }).catch((err) => {
        console.error("There was an issue removing the message.");
        return false
    });

    return response;
}

export async function manageRIQUsers(cosmosUsersToSend: CosmosUser[], add_user:boolean=false, delete_user:boolean=false, update_user:boolean=false): Promise<Response | null> {
    let response = null;
    if (add_user){
        response = await fetch(`/admin/cosmos_users/add`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cosmosUsersToSend),
        });
    }else if (delete_user){
        response = await fetch(`/admin/cosmos_users/delete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: cosmosUsersToSend[0].user_id }),
          });
          if (!response.ok) {
            throw new Error('Failed to remove user. Please try again later.');
          }
    }else if (update_user){
        response = await fetch('/admin/cosmos_users/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cosmosUsersToSend),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }else{
        response = null;
        throw new Error("No action specified for user management.")
    }


    if (!response.ok) {
        alert("Failed to modify users. Please try again later.");
        throw new Error(`Error: ${response.statusText}`);
    }
    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }else{
        console.log("Identity provider found.")
    }

    const payload = await response.json();
    return payload;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    // return null;
    return chatHistorySampleData;
}

export const fetchModuleListInit = (): Modules[] | null => {
    // Make initial API call here

    // return null;
    return moduleListSampleData;
}

export const historyList = async (offset=0): Promise<Conversation[] | null> => {
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                date: conv.createdAt,
                messages: []
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const sharedConv = async (convId: string): Promise<Conversation | null> => {
    const response = await fetch("/history/shared", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        const payload = await res.json();
        if (!payload) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        let messages: ChatMessage[] = [];
        if(payload?.messages){
            payload.messages.forEach((msg: any) => {
                const message: ChatMessage = {
                    id: msg.id,
                    role: msg.role,
                    date: msg.createdAt,
                    content: msg.content,
                    feedback: msg.feedback ?? undefined,
                    azureExtensionsContext: msg.azureExtensionsContext ?? undefined,
                    module_name: msg.module_name ?? undefined
                }
                messages.push(message)
            });
        }
        const conversation: Conversation = {
            id: payload.id,
            title: payload.title,
            date: payload.createdAt,
            messages: messages
        };
        return conversation;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const moduleList = async (offset=0): Promise<Modules[] | null> => {
    const response = await fetch(`/module/list`, {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const modules: Modules[] = await Promise.all(payload.map(async (mods: any) => {
            const module: Modules = {
                id: mods.id,
                name: mods.name,
                licensed: mods.licensed,
                moduleName: mods.moduleName,
                video: mods.video,
                definition: mods.definition,
                aiDefinition: mods.aiDefinition,
                searchIndex: mods.searchIndex,
                functionName: mods.functionName,
                quickQuery: mods.quickQuery,
                default: mods.default,
                in_scope: mods.in_scope,
                tool_call_limit: mods.tool_call_limit,
                requireFocus: mods.requireFocus,
                temperature: mods.temperature,
            };
            return module;
        }));
        return modules;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })
    return response
}

export const generateEML = async (content: string): Promise<Response | null> => {
    const response = await fetch('/generate-eml', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: content,
    });

    if (!response.ok) {
        return null;
    }
    return response;
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then(async (res) => {
        if(!res){
            return []
        }
        const payload = await res.json();
        let messages: ChatMessage[] = [];
        if(payload?.messages){
            payload.messages.forEach((msg: any) => {
                const message: ChatMessage = {
                    id: msg.id,
                    role: msg.role,
                    date: msg.createdAt,
                    content: msg.content,
                    feedback: msg.feedback ?? undefined,
                    azureExtensionsContext: msg.azureExtensionsContext ?? undefined,
                    module_name: msg.module_name ?? undefined
                }
                messages.push(message)
            });
        }
        return messages;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return []
    })
    return response
}

export const parseFile = async (file?: File): Promise<FileUploadResults[]> => {
    if (!file) {
        return [{"fileName": "null", "fileType": "null", "fileData": "No file was uploaded.","dataType":"null", "fileEncrypted": false}];
    }

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch("/file/upload", {
        method: "POST",
        body: formData,
    }).then(async (res) => {
        const payload = await res.json();
        return payload;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return [{"fileName": "null", "fileType": "null", "fileData": "No file found or could not parse file.","dataType":"error", "fileEncrypted": false}];
    })
    return response
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body;
    if(convId){
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages,
            focus: options.focus,
            client_state: options.client_state
        })
    }else{
        body = JSON.stringify({
            messages: options.messages,
            focus: options.focus,
            client_state: options.client_state
        })
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return new Response;
    })
    return response
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}

export const historyDelete = async (convId: string) : Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyDeleteAll = async () : Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyClear = async (convId: string) : Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyRename = async (convId: string, title: string) : Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.message){
            formattedResponse = CosmosDBStatus.Working
        }else{
            if(res.status === 500){
                formattedResponse = CosmosDBStatus.NotWorking
            }else if(res.status === 401){
                formattedResponse = CosmosDBStatus.InvalidCredentials    
            }else if(res.status === 422){ 
                formattedResponse = respJson.error    
            }else{
                formattedResponse = CosmosDBStatus.NotConfigured
            }
        }
        if(!res.ok){
            return {
                cosmosDB: false,
                status: formattedResponse
            }
        }else{
            return {
                cosmosDB: true,
                status: formattedResponse
            }
        }
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return {
            cosmosDB: false,
            status: err
        }
    })
    return response;
}

export const moduleEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/module/ensure", {
        method: "GET",
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.message){
            formattedResponse = CosmosDBStatus.Working
        }else{
            if(res.status === 500){
                formattedResponse = CosmosDBStatus.NotWorking
            }else if(res.status === 401){
                formattedResponse = CosmosDBStatus.InvalidCredentials    
            }else if(res.status === 422){ 
                formattedResponse = respJson.error    
            }else{
                formattedResponse = CosmosDBStatus.NotConfigured
            }
        }
        if(!res.ok){
            return {
                cosmosDB: false,
                status: formattedResponse
            }
        }else{
            return {
                cosmosDB: true,
                status: formattedResponse
            }
        }
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return {
            cosmosDB: false,
            status: err
        }
    })
    return response;
}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })
    return response
}

export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue logging feedback.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}